import React, { useState, useEffect } from "react";
import "./Home.css";
const Home = () => {


  return (
    <>
      <div className="heading">
        <h1>Join the Conversation</h1>
        <h3>AI Art Critic that evolves with the piece</h3>
      </div>
    </>
  )
}

export default Home;
