/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "convoapp-20210312140656-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://convoapp-20210312140656-hostingbucket-dev.s3-website-us-east-1.amazonaws.com",
    "aws_appsync_graphqlEndpoint": "https://tp75ujv7h5dbhmv5trlwre5y54.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-gjbmhorbgzd7xgrjxljhd5ehti",
    "aws_cloud_logic_custom": [
        {
            "name": "convorestapi",
            "endpoint": "https://br5blc9991.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:d1e3b04d-96d9-4883-8385-7d2067f9f917",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Q8JQ84cfr",
    "aws_user_pools_web_client_id": "6aaj0fv4t13rrc7jf1b0guhear",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "convoimages123418-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
